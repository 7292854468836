<template>
  <v-row
    class="category-wrapper"
    :class="$vuetify.breakpoint.lgAndUp ? 'flex-wrap' : 'flex-nowrap'"
  >
    <v-col
      id="pcard"
      cols="12"
      sm="6"
      md="4"
      lg="2"
      v-for="subCategory in categories"
      :key="subCategory.categoryId"
    >
      <v-card
        class="category-card pa-5 h-100 d-flex flex-column"
        outlined
        flat
        :to="{ name: 'Category', params: { pathMatch: subCategory.slug } }"
      >
        <v-img
          :src="src(subCategory)"
          :alt="subCategory.name"
          contain
          onerror="this.onerror=null;this.src='/no-icon.png'"
          width="'170'"
          height="'170'"
          class="mx-auto"
        />
        <v-card-title
          class="pb-0 px-0 justify-center text-center"
          style="height: fit-content"
        >
          {{ subCategory.name }}
        </v-card-title>
      </v-card>
    </v-col>
  </v-row>
</template>
<style lang="scss" scoped>
@media #{map-get($display-breakpoints, 'lg-and-down')} {
  .category-wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
  }
}
@media #{map-get($display-breakpoints, 'xs-only')} {
  #pcard {
    max-width: 85% !important;
  }
}
@media #{map-get($display-breakpoints, 'sm-only')} {
  #pcard {
    max-width: 45% !important;
  }
}

.category-card {
  box-shadow: 0px 3px 20px #00000029 !important;
  border: 0;
}
</style>
<script>
export default {
  name: "CategoryMainCard",
  props: {
    categories: { type: Array, required: true, default: () => [] }
  },
  data() {
    return {};
  },
  methods: {
    src(subCategory) {
      return subCategory.metaData?.category_info?.THUMB;
    }
  }
};
</script>
